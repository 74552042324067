@tailwind base;
@tailwind components;
@tailwind utilities;
 *{
    box-sizing: border-box;
    } 
    body{
      overflow-y: scroll;
      overflow-x: hidden;
      font-family:  'Poppins';
  
    }
    .poppins{
      font-family: 'poppins';
    }
    .container {
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
      height: 100vh;
      overflow-x: hidden;
    }
   Link{
    text-decoration: none;
    /* color: black; */
   }
   h1,h2,h3,h4,h5,h6{
   
    word-spacing: 6px;
    font-weight: 800;
     
}
.upcoming-container {
  position: relative;
  background: linear-gradient(to bottom, #800080, #FFA07A);
  background-size: 100% 400px;
  background-position: 0% 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: changeColor 5s infinite alternate;
}

.upcoming-text {
  font-size: 48px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
}
@keyframes changeColor {
  0% { background: linear-gradient(to bottom, #800080, #FFA07A); }
  50% { background: linear-gradient(to bottom, #FFA07A, #800080); }
  100% { background: linear-gradient(to bottom, #800080, #FFA07A); }
}

@keyframes changeText {
  0%, 100% { content: "UPCOMING"; }
  25% { content: "SOON"; }
  50% { content: "STAY TUNED"; }
  75% { content: "INCOMING"; }
}

.upcoming-text::before {
  content: "UPCOMING";
  animation: changeText 10s infinite;
}

 
.sectionlayout{
  max-width: 1640px;
  margin: auto;
  padding: 0 2.5rem;
}
@media (max-width: 1023px) {
  .sectionlayout{
    padding: 0 2rem;
  }
  
}
@media (max-width: 767px) {
  .sectionlayout{
  max-width: 1640px;
  margin: auto;
  padding: 0 1.75rem;
}
}

@media (max-width: 467px) {
  .sectionlayout{
    padding: 0 1rem;
  }
  
}


.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 1.5s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    