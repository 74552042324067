/* .vertical-slider {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
} */
.jb{
  justify-content: space-between;
}
.slide {
  min-height: 100vh; /* Adjusted to full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.slide h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.margin-left{
  margin-left:100% ;
}
.video.loaded {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.video {
  opacity: 0;
}

.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}